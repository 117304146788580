$NODE_ENV: "production";
$env: "production";
$rootSlug: "devowl-wp";
$slug: "real-admin";
$ANTD_PREFIX: "owl-antd";
$SUPPORTED_LANGS: ["en","de"];
/** This file is used to import 3rd party or own defined css styles. */

/**
    Themes
    @see https://codemirror.net/5/demo/theme.html
    Be sure to load resources used themes here.
*/
@import "codemirror/theme/abcdef.css";
@import "codemirror/lib/codemirror.css";

/**
    Importing react-quill css.
    @see https://github.com/zenoamaro/react-quill#with-webpack-or-create-react-app
*/
@import "react-quill/dist/quill.core.css";
@import "react-quill/dist/quill.snow.css";

/**
    Quill styles
    IMPORTANT: Only use ONE style.
    @see https://highlightjs.org/static/demo/
*/
// @import "highlight.js/styles/night-owl.css";
// @import "highlight.js/styles/darcula.css";
@import "highlight.js/styles/stackoverflow-light.css";

/**
    Custom background color of code-block
    @see https://github.com/quilljs/quill/issues/2231
*/
.ql-snow .ql-editor pre.ql-syntax {
    color: #2f3337;
    background-color: #f6f6f6;
    overflow: visible;
}

.ql-editor p {
    margin: 0.5em 0 !important;
    padding: 2px !important;
}

.ql-tooltip {
    z-index: 1;
}

@media all and (max-width: 1284px) {
    .owl-antd-form-item-label {
        text-align: left !important;
    }
}

.owl-antd-select-selection-overflow-item {
    padding: 0rem 0.15rem;
}

.owl-antd-radio-button-wrapper-disabled.owl-antd-radio-button-wrapper-checked {
    font-weight: bolder;
}

.owl-antd-quill-wrapper {
    background-color: white;
}

.owl-antd-quill-wrapper-disabled {
    background-color: #f5f5f5;
}

.owl-antd-input-disabled-translatable-default > input {
    color: rgba(0, 0, 0, 0.85) !important;
}

/**
* Workaround for cut-off icons on expanded rows (table)
* Task: (CU-861n1cg3c)
* @see:  https://app.clickup.com/t/861n1cg3c?comment=90090030771894&threadedComment=90090030809518
*/
.owl-antd-table-row-expand-icon {
    transform: initial !important;
}

#scroll-progress {
    position: fixed;
    bottom: 0;
    width: 0%;
    height: 3px;
    background: #1890ff;
    z-index: 10000;
}

.crossClip {
    clip-path: polygon(
        20% 0%,
        0% 20%,
        30% 50%,
        0% 80%,
        20% 100%,
        50% 70%,
        80% 100%,
        100% 80%,
        70% 50%,
        100% 20%,
        80% 0%,
        50% 30%
    );
}

/**
    Styles for drag and drop items
*/
@import "../components/third-party/dnd/styles.css";
